<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 网络错误 -->
		<el-row v-if="value==1" style="margin-top:96px;">
			<el-row>
				<el-image src="/index/error.png" class="error-img"></el-image>
			</el-row>
			<el-row class="error-message">
				NetWork-BAD
			</el-row>
		</el-row>
		
		<!-- 没有数据 -->
		<el-row v-else  style="margin-top:96px;">
			<el-row>
				<el-image src="/index/nodata.png" class="error-img"></el-image>
			</el-row>
			<el-row class="error-message">
				Empty data
			</el-row>
		</el-row>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
			
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
	// 头部组件
	import headLogo from '@/components/headLogo.vue'
	// 底部导航
	import bottomNavigation from '@/components/bottomNavigation.vue'
	// 菜单导航
	import meauDialog from '@/components/meauDialog.vue'
	export default
	{
		data()
		{
			return{
				value:1,
				// 导航值
				navigationvalue:false,
			}
		},
		components:
		{
			headLogo,
			bottomNavigation,
			meauDialog
		},
		created()
		{
			console.log(this.$route.query.item)
			this.value=this.$route.query.item;
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			}
		}
	}
</script>

<style scoped>
	.error-img{
		width: 200px;
		height: 200px;
		margin-top: 100px;
	}
	.error-message{
		margin-top: 30px;
		font-size: 20px;
		color: #3B519D;
		margin-bottom: 50px;
	}
</style>